// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import GlobalStyles from "./globalStyles";
import Header from "./components/Header/Header";
import TempNavBarDir from "./components/TemporaryNavBarDirectory/TempNavBarDir";
import ScrollToTop from "./components/ScrollToTop";

import MainLayout from "./components/MainLayout"; //English NavBar Footer
import MainLayoutC from "./components/MainLayoutC"; //Chinese NavBar Footer
import SimpleLayout from "./components/SimpleLayout"; //No NavbarBottom.js

//HOME PAGE
import HomePage from "./pages/homepage/HomePage"; //Currently Classes.js
import HomePageC from "./pages/homepage/HomePageC";
//ClassDetails, ClassDetailsC
import ClassDetails from "./pages/classes/ClassDetails";
import ClassDetailsC from "./pages/classes/ClassDetailsC";

//ClassConfirmPayment, ClassConfirmPaymentC,
import ClassConfirmPayment from "./pages/classes/ClassConfirmPayment";
import ClassConfirmPaymentC from "./pages/classes/ClassConfirmPaymentC";

//LOGIN, SIGNUP, RESET PASSWORD, CONFIRM NEW PASSWORD, SUCCESSFUL
import Login from "./pages/loginpages/Login";
import LoginC from "./pages/loginpages/LoginC";
import SignUp from "./pages/loginpages/SignUp";
import SignUpC from "./pages/loginpages/SignUpC";
import Verify from "./pages/loginpages/Verify";
import ResetPassword from "./pages/loginpages/ResetPassword";
import ResetPasswordC from "./pages/loginpages/ResetPasswordC";
import ConfirmNewPassword from "./pages/loginpages/ConfirmNewPassword";
import ConfirmNewPasswordC from "./pages/loginpages/ConfirmNewPasswordC";
import Successful from "./pages/loginpages/Successful";
import SuccessfulC from "./pages/loginpages/SuccessfulC";

import Bookings from "./pages/bookings/Bookings";
import BookingsC from "./pages/bookings/BookingsC";

// Orders
import Order from "./pages/orders/Order";
import OrderC from "./pages/orders/OrderC";

import ConfirmAttendance from "./pages/attendance/ConfirmAttendance";
import ConfirmAttendanceC from "./pages/attendance/ConfirmAttendanceC";

import Notifications from "./pages/notifications/Notifications";
import NotificationsC from "./pages/notifications/NotificationsC";
import Users from "./pages/users/Users";
import UsersC from "./pages/users/UsersC";
import MyProfileUpdate from "./pages/users/MyProfileUpdate";
import MyProfileUpdateC from "./pages/users/MyProfileUpdateC";

//TESTING SINGLE PAGE ONLY
// import QrCode from "./components/QrCode/QrCodeTicket";
import QrCodeC from "./components/QrCode/QrCodeC";
import LoginTemplate from "./pages/loginpages/LoginTemplate/LoginTemplate";
import GlobalStylesTester from "./components/GlobalStylesTester";
// import TestGlobalStyles from "./pages/TestGlobalStyles";






function App() {
  return (
    <Router>
      <ScrollToTop />
      {/* <TempNavBarDir /> */}
      <GlobalStyles />
      <Header />
      <Routes>
        <Route
          path="/en/"
          element={
            <MainLayout>
              <HomePage />
            </MainLayout>
          }
        />
          <Route
        path="/"
        element={<Navigate to="/en/" replace />}
      />
        <Route
          path="/en/program/:program_code"
          element={
            <SimpleLayout>
              <ClassDetails />
            </SimpleLayout>
          }
        />
        <Route
            path="/ch/program/:program_code"
          element={
            <SimpleLayout>
              <ClassDetailsC />
            </SimpleLayout>
          }
        />
        <Route
          path="/en/classconfirmpayment"
          element={
            <SimpleLayout>
              <ClassConfirmPayment />
            </SimpleLayout>
          }
        />
        <Route
          path="/ch/classconfirmpayment"
          element={
            <SimpleLayout>
              <ClassConfirmPaymentC />
            </SimpleLayout>
          }
        />

         {/* <Route
          path="/order/:id"
          element={
            <SimpleLayout>
              <Order/>
            </SimpleLayout>
          }
        /> */}
          <Route
          path="/en/order/:token"
          element={
            <SimpleLayout>
              <Order/>
            </SimpleLayout>
          }
        />
         <Route
          path="/ch/order/:token"
          element={
            <SimpleLayout>
              <OrderC/>
            </SimpleLayout>
          }
        />   <Route
          path="/en/attendance/confirm/:token"
          element={
            <SimpleLayout>
              <ConfirmAttendance/>
            </SimpleLayout>
          }
        />
        <Route
          path="/ch/attendance/confirm/:token"
          element={
            <SimpleLayout>
              <ConfirmAttendanceC/>
            </SimpleLayout>
          }
        />
        <Route
          path="/en/bookings"
          element={
            <MainLayout>
              <Bookings />
            </MainLayout>
          }
        />
        <Route
          path="/notifications"
          element={
            <MainLayout>
              <Notifications />
            </MainLayout>
          }
        />
        <Route
          path="/users"
          element={
            <MainLayout>
              <Users />
            </MainLayout>
          }
        />
        <Route
          path="/usersC"
          element={
            <MainLayout>
              <UsersC />
            </MainLayout>
          }
        />
        <Route
          path="/myprofileupdate"
          element={
            <SimpleLayout>
              <MyProfileUpdate />
            </SimpleLayout>
          }
        />
        <Route
          path="/myprofileupdateC"
          element={
            <SimpleLayout>
              <MyProfileUpdateC />
            </SimpleLayout>
          }
        />
        <Route
          path="/logintemplate"
          element={
            <MainLayout>
              <LoginTemplate />
            </MainLayout>
          }
        />
        {/* SAMPLE Pages for LOGIN, SIGNUP, RESET PASSWORD, CONFIRM NEW PASSWORD, SUCCESSFUL */}
        <Route
          path="/en/login"
          element={
            <SimpleLayout>
              <Login />
            </SimpleLayout>
          }
        />
        <Route
          path="/loginC"
          element={
            <SimpleLayout>
              <LoginC />
            </SimpleLayout>
          }
        />
        <Route
          path="/en/signup"
          element={
            <SimpleLayout>
              <SignUp />
            </SimpleLayout>
          }
        />
        <Route
          path="/signupC"
          element={
            <SimpleLayout>
              <SignUpC />
            </SimpleLayout>
          }
        />
         <Route
          path="/en/signup/verify/:token"
          element={
            <SimpleLayout>
              <Verify/>
            </SimpleLayout>
          }
        />

 
        <Route
          path="/en/resetpassword"
          element={
            <SimpleLayout>
              <ResetPassword />
            </SimpleLayout>
          }
        />
        <Route
          path="/resetpasswordC"
          element={
            <SimpleLayout>
              <ResetPasswordC />
            </SimpleLayout>
          }
        />
        <Route
          path="/confirmnewpassword"
          element={
            <SimpleLayout>
              <ConfirmNewPassword />
            </SimpleLayout>
          }
        />
        <Route
          path="/confirmnewpasswordC"
          element={
            <SimpleLayout>
              <ConfirmNewPasswordC />
            </SimpleLayout>
          }
        />
        <Route
          path="/successful"
          element={
            <SimpleLayout>
              <Successful />
            </SimpleLayout>
          }
        />
        <Route
          path="/successfulC"
          element={
            <SimpleLayout>
              <SuccessfulC />
            </SimpleLayout>
          }
        />
        {/* SAMPLE OF Page without NavbarBottom: */}
        {/* <Route
          path="/booking/:id"
          element={
            <SimpleLayout>
              <QrCode />
            </SimpleLayout>
          }
        /> */}
        {/* SAMPLE OF Page without NavbarBottom: */}
        <Route
          path="/globalStylesTester"
          element={
            <SimpleLayout>
              <GlobalStylesTester />
            </SimpleLayout>
          }
        />
        {/* CHINESE PAGES */}
        <Route
          path="/homepageC"
          element={
            <MainLayoutC>
              <HomePageC />
            </MainLayoutC>
          }
        />
        <Route
          path="/bookingsC"
          element={
            <MainLayoutC>
              <BookingsC />
            </MainLayoutC>
          }
        />
        <Route
          path="/notificationsC"
          element={
            <MainLayoutC>
              <NotificationsC />
            </MainLayoutC>
          }
        />
        <Route
          path="/myprofileupdateC"
          element={
            <SimpleLayout>
              <MyProfileUpdateC />
            </SimpleLayout>
          }
        />
        <Route
          path="/myprofileupdateC"
          element={
            <SimpleLayout>
              <MyProfileUpdateC />
            </SimpleLayout>
          }
        />
        {/* SAMPLE OF Page without NavbarBottom: */}
        <Route
          path="/qrcodeC"
          element={
            <SimpleLayout>
              <QrCodeC />
            </SimpleLayout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;