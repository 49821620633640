import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import api from '../../Api';
import Cookies from 'js-cookie';

const ClassCard = ({ id, code, name, venue, images, color, category, age }) => (
  <Link to={`program/${code}`}>
    <CardWrapper>
      <ClassImage src={images} alt={name} loading="lazy" />
      <CategoryTag style={{ backgroundColor: color }}>{category}</CategoryTag>
      <ClassInfo>
        <ClassTitle>{name}</ClassTitle>
        <ClassDetails>
          <VenueWrapper>
            <VenueIcon>📍</VenueIcon>
            <ClassVenue>{venue}</ClassVenue>
          </VenueWrapper>
          <AgeGroup>Age: {age}</AgeGroup>
        </ClassDetails>
      </ClassInfo>
    </CardWrapper>
  </Link>
);

const Classes = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get('/classBooking/api/class/');
        setData(res.data);
        const uniqueCategories = [...new Set(res.data.map(item => item.category))];
        setCategories(['All', ...uniqueCategories]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    Cookies.remove('activeCourse');
  }, []);

  useEffect(() => {
    let filtered = [...data];
    
    if (selectedCategory !== 'All') {
      filtered = filtered.filter(item => item.category === selectedCategory);
    }
    
    if (searchQuery) {
      filtered = filtered.filter(item => 
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.venue.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    
    setFilteredData(filtered);
  }, [data, selectedCategory, searchQuery]);

  if (loading) {
    return <LoadingSpinner>Loading...</LoadingSpinner>;
  }

  return (
    <PageWrapper>
      <Header>
        <HeaderTitle>Our Classes</HeaderTitle>
        <HeaderSubtitle>Discover and book your perfect class</HeaderSubtitle>
      </Header>
      
      <FilterSection>
        <SearchBar
          type="text"
          placeholder="Search classes..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <CategoryFilter>
          {categories.map(category => (
            <CategoryButton
              key={category}
              active={selectedCategory === category}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </CategoryButton>
          ))}
        </CategoryFilter>
      </FilterSection>

      <GridContainer>
        {filteredData.map((classItem, index) => (
          <ClassCard key={index} {...classItem} />
        ))}
        {filteredData.length === 0 && (
          <NoResults>No classes found matching your criteria</NoResults>
        )}
      </GridContainer>
    </PageWrapper>
  );
};

export default Classes;

// Styled Components
const PageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
  font-family: var(--font-poppins);
`;

const HeaderSubtitle = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const FilterSection = styled.div`
  margin-bottom: 30px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #eee;
  border-radius: 25px;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
  }
`;

const CategoryFilter = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

const CategoryButton = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background-color: ${props => props.active ? '#007bff' : '#eee'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#0056b3' : '#ddd'};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  padding: 20px 0;
`;

const CardWrapper = styled.article`
  position: relative;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ClassImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CategoryTag = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
`;

const ClassInfo = styled.div`
  padding: 20px;
`;

const ClassTitle = styled.h3`
  font-family: var(--font-poppins);
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 0 10px 0;
  color: #333;
`;

const ClassDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const VenueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const VenueIcon = styled.span`
  font-size: 1rem;
`;

const ClassVenue = styled.span`
  color: #666;
  font-size: 0.9rem;
`;

const AgeGroup = styled.span`
  color: #666;
  font-size: 0.9rem;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #666;
`;

const NoResults = styled.div`
  grid-column: 1 / -1;
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1rem;
`;
